import React from 'react'
import Icon from './'
import generateHash from '../../../../utils/generate-hash'

const Doctor = props => {
  const id = generateHash()

  return (
    <Icon
      {...{
        fill: '#39d678',
        ...props
      }}
    >
      <defs>
        <clipPath id={id}>
          <rect x="3.42" y="1" width="57.16" height="62" />
        </clipPath>
      </defs>
      <g
        style={{
          clipPath: `url(#${id})`
        }}
      >
        <path d="M4.39,38H18.33l5-21.88a2.43,2.43,0,0,1,4.77.23l4.16,31.6,4-19.75A2.42,2.42,0,0,1,41,27.92l3.07,10h16.4a167,167,0,0,0-.23-16.74A8.58,8.58,0,0,0,56,14.75C48.93,10.42,42,6,35.45,2a6.38,6.38,0,0,0-6.9,0C22,6,15.07,10.45,8,14.75a8.63,8.63,0,0,0-4.21,6.46,166.51,166.51,0,0,0-.22,17A1.45,1.45,0,0,1,4.39,38Z" />
        <path d="M43.7,40.85a2.39,2.39,0,0,1-2.31-1.71L38.77,30.6,34.56,51.32a2.38,2.38,0,0,1-2.37,1.94H32.1a2.37,2.37,0,0,1-2.31-2.1L25.6,19.34,21.07,39a2.4,2.4,0,0,1-2.36,1.87H4.39a1.46,1.46,0,0,1-.72-.19c0,.71.06,1.42.1,2.13A8.58,8.58,0,0,0,8,49.25C15.07,53.55,22,58,28.55,62a6.38,6.38,0,0,0,6.9,0C42,58,48.93,53.55,56,49.25a8.5,8.5,0,0,0,4.21-6.46c0-.65.06-1.29.09-1.94Z" />
      </g>
    </Icon>
  )
}

export default Doctor
